import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { themeCreator } from './base';
import { StylesProvider } from '@material-ui/styles';
// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';
// import stylisRTLPlugin from 'stylis-plugin-rtl';

// const cacheRtl = createCache({
//   key: 'bloom-ui',
// prepend: true,
//   // @ts-ignore
//   stylisPlugins: [stylisRTLPlugin]
// });

// const themeMap = {
//   PureLightTheme,
//   LightBloomTheme,
//   GreyGooseTheme,
//   PurpleFlowTheme,
//   NebulaFighterTheme,
//   DarkSpacesTheme,
//   GreenFieldsTheme,
//   OperationTheme
// };

export const ThemeContext = React.createContext((themeName) => {});

const ThemeProviderWrapper = function (props) {
  const curThemeName = localStorage.getItem('appTheme') || 'OperationTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName) => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    <StylesProvider injectFirst>
      {/* <CacheProvider value={cacheRtl}> */}
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
      {/* </CacheProvider> */}
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
