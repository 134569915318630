import { Box, IconButton, Hidden, Popover, Tooltip } from '@material-ui/core';
import { useRef, useState } from 'react';

import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';

import DateRangeToggle from './DateRangeToggle';
import { useTranslation } from 'react-i18next';

import useGlobalDate from 'src/hooks/useGlobalDate';

function DateButton() {
  const { t } = useTranslation();

  const { startGlobalDate, endGlobalDate } = useGlobalDate();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('Click to change date')}>
        <IconButton
          style={{ fontSize: 16 }}
          // color="primary"
          ref={ref}
          onClick={handleOpen}
        >
          <CalendarTodayOutlined style={{ marginRight: 10 }} />
          <Hidden smDown>{startGlobalDate.toDateString()}</Hidden>
          <Hidden smDown>{' - '}</Hidden>
          <Hidden smDown>{endGlobalDate.toDateString()}</Hidden>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <DateRangeToggle />
      </Popover>
    </>
  );
}

export default DateButton;
