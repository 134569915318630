import AnalyticsTwoToneIcon from '@material-ui/icons/AnalyticsTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import StoreTwoToneIcon from '@material-ui/icons/StoreTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import KitchenTwoToneIcon from '@material-ui/icons/KitchenTwoTone';
import HealthAndSafetyTwoToneIcon from '@material-ui/icons/HealthAndSafetyTwoTone';
import ContactSupportTwoToneIcon from '@material-ui/icons/ContactSupportTwoTone';
import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';
import TaskAltTwoToneIcon from '@material-ui/icons/TaskAltTwoTone';
import EventNoteTwoToneIcon from '@material-ui/icons/EventNoteTwoTone';
import DocumentScannerTwoToneIcon from '@material-ui/icons/DocumentScannerTwoTone';
import WorkTwoToneIcon from '@material-ui/icons/WorkTwoTone';
import MarkunreadMailboxTwoToneIcon from '@material-ui/icons/MarkunreadMailboxTwoTone';
import QuestionAnswerTwoToneIcon from '@material-ui/icons/QuestionAnswerTwoTone';
import DashboardCustomizeTwoToneIcon from '@material-ui/icons/DashboardCustomizeTwoTone';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@material-ui/icons/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@material-ui/icons/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@material-ui/icons/DesignServicesTwoTone';
import SupportTwoToneIcon from '@material-ui/icons/SupportTwoTone';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';

const menuItems = [
  {
    heading: 'Dashboards',
    items: [
      {
        name: 'Overview',
        icon: AnalyticsTwoToneIcon,
        link: '/dashboards/myanalytics'
      },
      // {
      //   name: 'Campaigns',
      //   icon: AnalyticsTwoToneIcon,
      //   link: '/dashboards/analytics'
      // },
      // {
      //   name: 'Placements',
      //   icon: AnalyticsTwoToneIcon,
      //   link: '/dashboards/analytics'
      // },
      // {
      //   name: 'Sites',
      //   icon: AnalyticsTwoToneIcon,
      //   link: '/dashboards/analytics'
      // },
      // {
      //   name: 'Ads',
      //   icon: AnalyticsTwoToneIcon,
      //   link: '/dashboards/analytics'
      // },
      // {
      //   name: 'Criatives',
      //   icon: AnalyticsTwoToneIcon,
      //   link: '/dashboards/analytics'
      // }
      {
        name: 'Banking',
        icon: AccountBalanceTwoToneIcon,
        link: '/dashboards/banking',
        badge: 'New'
      },
      {
        name: 'Commerce',
        icon: StoreTwoToneIcon,
        link: '/dashboards/commerce'
      },
      {
        name: 'Crypto',
        icon: AccountBalanceWalletTwoToneIcon,
        link: '/dashboards/crypto'
      },
      {
        name: 'Finance',
        icon: MonetizationOnTwoToneIcon,
        link: '/dashboards/finance'
      },
      {
        name: 'Fitness',
        icon: KitchenTwoToneIcon,
        link: '/dashboards/fitness'
      },
      {
        name: 'Healthcare',
        icon: HealthAndSafetyTwoToneIcon,
        link: '/dashboards/healthcare',
        items: [
          {
            name: 'Doctors Page',
            badge: 'Hot',
            link: '/dashboards/healthcare/doctor'
          },
          {
            name: 'Hospital Overview',
            link: '/dashboards/healthcare/hospital'
          }
        ]
      },
      {
        name: 'Helpdesk',
        icon: ContactSupportTwoToneIcon,
        link: '/dashboards/helpdesk'
      },
      {
        name: 'Learning',
        icon: LocalLibraryTwoToneIcon,
        link: '/dashboards/learning'
      },
      {
        name: 'Monitoring',
        icon: DnsTwoToneIcon,
        link: '/dashboards/monitoring'
      },
      {
        name: 'Tasks',
        icon: TaskAltTwoToneIcon,
        link: '/dashboards/tasks'
      }
    ]
  },
  {
    heading: 'Applications',
    items: [
      {
        name: 'Calendar',
        icon: EventNoteTwoToneIcon,
        link: '/applications/calendar'
      },
      {
        name: 'File Manager',
        icon: DocumentScannerTwoToneIcon,
        link: '/applications/file-manager'
      },
      {
        name: 'Jobs Platform',
        icon: WorkTwoToneIcon,
        link: '/applications/jobs-platform'
      },
      {
        name: 'Mailbox',
        icon: MarkunreadMailboxTwoToneIcon,
        link: '/applications/mailbox/inbox'
      },
      {
        name: 'Messenger',
        icon: QuestionAnswerTwoToneIcon,
        link: '/applications/messenger'
      },
      {
        name: 'Projects Board',
        icon: DashboardCustomizeTwoToneIcon,
        link: '/applications/projects-board'
      }
    ]
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: '/management/users',
        items: [
          {
            name: 'List All',
            link: '/management/users'
          },
          {
            name: 'User Profile',
            link: '/management/user/2'
          }
        ]
      },
      {
        name: 'Projects',
        icon: AccountTreeTwoToneIcon,
        link: '/management/projects/list'
      },
      {
        name: 'Commerce',
        icon: StorefrontTwoToneIcon,
        link: '/management/commerce',
        items: [
          {
            name: 'Shop',
            link: '/management/commerce/shop'
          },
          {
            name: 'Products List',
            link: '/management/commerce/products'
          },
          {
            name: 'View Product',
            link: '/management/commerce/product/2'
          },
          {
            name: 'Create Product',
            link: '/management/commerce/product/create'
          }
        ]
      },
      {
        name: 'Invoices',
        icon: ReceiptTwoToneIcon,
        link: '/management/invoices',
        items: [
          {
            name: 'List All',
            link: '/management/invoices/list'
          },
          {
            name: 'View Single',
            link: '/management/invoice/7'
          }
        ]
      }
    ]
  },
  {
    heading: 'Extra Pages',
    items: [
      {
        name: 'Auth Pages',
        icon: VpnKeyTwoToneIcon,
        link: '/auth',
        items: [
          {
            name: 'Login',
            items: [
              {
                name: 'Basic',
                link: '/account/login-basic'
              },
              {
                name: 'Cover',
                link: '/account/login-cover'
              }
            ]
          },
          {
            name: 'Register',
            items: [
              {
                name: 'Basic',
                link: '/account/register-basic'
              },
              {
                name: 'Cover',
                link: '/account/register-cover'
              },
              {
                name: 'Wizard',
                link: '/account/register-wizard'
              }
            ]
          },
          {
            name: 'Recover Password',
            link: '/account/recover-password'
          }
        ]
      },
      {
        name: 'Status',
        icon: ErrorTwoToneIcon,
        link: '/status',
        items: [
          {
            name: 'Error 404',
            link: '/status/404'
          },
          {
            name: 'Error 500',
            link: '/status/500'
          },
          {
            name: 'Maintenance',
            link: '/status/maintenance'
          },
          {
            name: 'Coming Soon',
            link: '/status/coming-soon'
          }
        ]
      }
    ]
  },
  {
    heading: 'Foundation',
    items: [
      {
        name: 'Overview',
        link: '/overview',
        icon: DesignServicesTwoToneIcon
      }
    ]
  }
];

export default menuItems;
