import { Box, Hidden, IconButton } from '@material-ui/core';
import { useRef, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import DateButton from './Date';
import RefreshButton from './Refresh';
import ExportButton from './Export';
import { useTheme } from '@material-ui/core/styles';

import useGlobalDate from 'src/hooks/useGlobalDate';

function HeaderButtons({ handleRefresh }) {
  const ref = useRef(null);
  const theme = useTheme();
  const { startGlobalDate, endGlobalDate, key } = useGlobalDate();
  const [iconColor, setIconColor] = useState('secondary');
  const [bFirstTime, setBFirstTime] = useState(true);
  const handleRefresh2 = () => {
    setIconColor('secondary');
    handleRefresh();
  };

  useEffect(() => {
    if (bFirstTime) {
      setBFirstTime(false);
      return;
    }

    setIconColor('primary');
  }, [startGlobalDate, endGlobalDate]);

  const [isSidebarOptionsHidden, setIsSidebarOptionsHidden] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.indexOf('/dashboards/site/') != -1)
      setIsSidebarOptionsHidden(true);
  }, [pathname]);

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const overviewData = XLSX.utils.json_to_sheet(
      [
        {
          Impressions: 14290504,
          'Non-Viewables': 14290504,
          Viewables: 14290504,
          'Viewability (IAB)': 14290504,
          Clicks: 14290504,
          Views: 14290504,
          CTR: 14290504,
          VTR: 14290504
        }
      ],
      {
        header: [
          'Impressions',
          'Non-Viewables',
          'Viewables',
          'Viewability (IAB)',
          'Clicks',
          'Views',
          'CTR',
          'VTR'
        ]
      }
    );

    const ws = XLSX.utils.json_to_sheet(
      [
        {
          0: 0,
          1: 1,
          2: 2
        }
      ],
      {
        header: ['0', '1', '2']
      }
    );

    overviewData['A1'].s = {
      // set the style for target cell
      font: {
        sz: 24,
        bold: true,
        color: {
          rgb: 'FFFFAA00'
        }
      }
    };

    XLSX.utils.book_append_sheet(wb, overviewData, 'Overview'); // add overviewData to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'sheet2'); // add worksheet to workbook

    XLSX.writeFile(wb, 'test.xlsx'); // write workbook
  };

  return (
    <Box sx={{ mr: 1 }}>
      {!isSidebarOptionsHidden && (
        <>
          <DateButton />

          <RefreshButton
            color={iconColor}
            onClick={handleRefresh2}
          ></RefreshButton>

          <ExportButton color={'primary'}></ExportButton>
        </>
      )}
    </Box>
  );
}

export default HeaderButtons;
