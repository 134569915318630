import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Hidden,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import Auth0Login from '../LoginAuth0';
import FirebaseAuthLogin from '../LoginFirebaseAuth';
import JWTLogin from '../LoginJWT';
import AmplifyLogin from '../LoginAmplify';

import { useTranslation } from 'react-i18next';
import { experimentalStyled } from '@material-ui/core/styles';
import Logo from 'src/components/Logo';
import { Scrollbars } from 'react-custom-scrollbars-2';

const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
  Amplify: '/static/images/logo/amplify.svg'
};

const Content = experimentalStyled(Box)(
  () => `
    display: flex;
    height: 100%;
    flex: 1;
`
);

const MainContent = experimentalStyled(Box)(
  () => `
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
`
);

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = experimentalStyled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: ${theme.spacing(6)};
`
);

const CardImg = experimentalStyled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const TypographyH1 = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

function LoginCover() {
  const { method } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Login - Cover</title>
      </Helmet>
      <Content>
        <Hidden mdDown>
          <SidebarWrapper>
            <Scrollbars autoHide>
              <SidebarContent>
                <Logo />
                <Box mt={6}>
                  <TypographyH1 variant="h1" sx={{ mb: 7 }}>
                    {t('Multiple auth methods included')}
                  </TypographyH1>
                  <Box sx={{ position: 'relative', width: 300, height: 120 }}>
                    <Tooltip arrow placement="top" title="Auth0">
                      <CardImg
                        sx={{ width: 80, height: 80, left: -20, top: -40 }}
                      >
                        <img width={40} alt="Auth0" src={icons['Auth0']} />
                      </CardImg>
                    </Tooltip>
                    <Tooltip arrow placement="top" title="Firebase">
                      <CardImg sx={{ width: 90, height: 90, left: 70 }}>
                        <img
                          width={50}
                          alt="Firebase"
                          src={icons['FirebaseAuth']}
                        />
                      </CardImg>
                    </Tooltip>
                    <Tooltip arrow placement="top" title="JSON Web Token">
                      <CardImg
                        sx={{ width: 110, height: 110, top: -30, left: 170 }}
                      >
                        <img
                          width={80}
                          alt="JSON Web Token"
                          src={icons['JWT']}
                        />
                      </CardImg>
                    </Tooltip>
                    <Tooltip arrow placement="top" title="AWS Amplify">
                      <CardImg
                        sx={{ width: 70, height: 70, bottom: 0, right: -55 }}
                      >
                        <img width={50} alt="Amplify" src={icons['Amplify']} />
                      </CardImg>
                    </Tooltip>
                  </Box>
                  <Typography variant="subtitle1" sx={{ my: 3 }}>
                    {t(
                      'Choose between JSON Web Token, Firebase, AWS Amplify or Auth0. Regular login/register functionality is also available.'
                    )}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    fontWeight="bold"
                  >
                    {t('Want to switch auth methods?')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t(
                      'It only takes seconds. There is a documentation section showing how to do exactly that'
                    )}
                    .{' '}
                    <Link component={RouterLink} underline="hover" to="/docs">
                      Read docs
                    </Link>
                  </Typography>
                </Box>
              </SidebarContent>
            </Scrollbars>
          </SidebarWrapper>
        </Hidden>
        <MainContent>
          <Container maxWidth="sm">
            <Card sx={{ px: 4, py: 5 }}>
              <Box textAlign="center">
                <Typography variant="h2" sx={{ mb: 1 }}>
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 3 }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              {method === 'Auth0' && <Auth0Login />}
              {method === 'FirebaseAuth' && <FirebaseAuthLogin />}
              {method === 'JWT' && <JWTLogin />}
              {method === 'Amplify' && <AmplifyLogin />}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
