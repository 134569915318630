import { IconButton } from '@material-ui/core';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';

function RefreshButton({ onClick, color }) {
  return (
    <>
      <IconButton
        style={{ fontSize: 16, marginLeft: 10 }}
        color={color}
        onClick={onClick}
      >
        <RefreshOutlined style={{ marginRight: 6 }} />
        {'Refresh'}
      </IconButton>
    </>
  );
}

export default RefreshButton;
