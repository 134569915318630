import { Box, Hidden, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const LogoWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = experimentalStyled(Box)(
  () => `
        width: 52px;
        height: 38px;
        margin-top: 4px;
        transform: scale(.8);
`
);

const AudienzLogo = experimentalStyled(Box)(
  ({ theme }) => `
        background: url("https://cdn.digitalaudienz.com/logo-operation-48.png") no-repeat fixed center;
        position: relative;
        top: 3px;
        left: 17px;
        width: 32px;
        height: 32px;
`
);

const LogoSign = experimentalStyled(Box)(
  ({ theme }) => `
        background: 'url("https://cdn.digitalaudienz.com/logo-operation-48.png") no-repeat fixed center;';
        width: 18px;
        height: 18px;
        border-radius: ${theme.general.borderRadiusSm};
        position: relative;
        transform: rotate(45deg);
        top: 3px;
        left: 17px;

        // &:after, 
        // &:before {
        //     content: "";
        //     display: block;
        //     width: 18px;
        //     height: 18px;
        //     position: absolute;
        //     top: -1px;
        //     right: -20px;
        //     transform: rotate(0deg);
        //     border-radius: ${theme.general.borderRadiusSm};
        // }

        // &:before {
        //     background: ${theme.palette.primary.main};
        //     right: auto;
        //     left: 0;
        //     top: 20px;
        // }

        // &:after {
        //     background: ${theme.palette.secondary.main};
        // }
`
);

const LogoSignInner = experimentalStyled(Box)(
  ({ theme }) => `
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 5;
        border-radius: ${theme.general.borderRadiusSm};
        background: ${theme.header.background};
`
);

const LogoTextWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
`
);

const VersionBadge = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.palette.success.main};
        color: ${theme.palette.success.contrastText};
        padding: ${theme.spacing(0.4, 1)};
        border-radius: ${theme.general.borderRadiusSm};
        text-align: center;
        display: inline-block;
        line-height: 1;
        font-size: ${theme.typography.pxToRem(11)};
`
);

const LogoText = experimentalStyled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(15)};
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.colors.alpha.black[100]};
`
);

function Logo() {
  const { t } = useTranslation();

  const mystyle = {
    backgroundImage:
      'url("https://cdn.digitalaudienz.com/logo-operation-48.png")',
    backgroundSize: 'cover',
    position: 'relative',
    top: '-5px',
    left: '5px',
    width: '48px',
    height: '48px',
    display: 'block'
  };

  return (
    <LogoWrapper>
      <LogoSignWrapper>
        <span style={mystyle} />
      </LogoSignWrapper>
      {/* <Hidden smDown> */}
      <LogoTextWrapper>
        <LogoText sx={{ mt: 1.6 }}>OPERATION</LogoText>
      </LogoTextWrapper>
      {/* </Hidden> */}
    </LogoWrapper>
  );
}

export default Logo;
