import React, { useState } from 'react';
import { experimentalStyled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Outlet, useOutletContext } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';

import useGlobalDate from 'src/hooks/useGlobalDate';

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        // @media (min-width: ${theme.breakpoints.values.lg}px) {
        //     padding-left: ${theme.sidebar.width};
        // }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const SidebarLayout = () => {
  const { startGlobalDate, endGlobalDate, update } = useGlobalDate();
  const handleRefresh = () => {
    update(
      startGlobalDate.getTime().toString() + endGlobalDate.getTime().toString()
    );
  };
  return (
    <>
      {/* <Sidebar /> */}
      <MainWrapper>
        <Header handleRefresh={handleRefresh} />
        <MainContent>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default SidebarLayout;
