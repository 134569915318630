import { createContext, useEffect, useReducer } from 'react';
import axios from 'src/utils/axios';
import { verify, JWT_SECRET } from 'src/utils/jwt';
import PropTypes from 'prop-types';
import { addDays, subDays } from 'date-fns';

const initialGlobalDateState = {
  startGlobalDate: subDays(new Date(), 1),
  endGlobalDate: new Date(),
  key: ''
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { startGlobalDate, endGlobalDate } = action.payload;

    return {
      ...state,
      startGlobalDate,
      endGlobalDate
    };
  },
  CHANGE: (state, action) => {
    const { startGlobalDate, endGlobalDate } = action.payload;

    return {
      ...state,
      startGlobalDate,
      endGlobalDate
    };
  },
  UPDATE: (state, action) => {
    const { key } = action.payload;
    return {
      ...state,
      key
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const GlobalDateContext = createContext({
  ...initialGlobalDateState,
  setDateRange: () => Promise.resolve(),
  update: () => Promise.resolve()
});

export const GlobalDateProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialGlobalDateState);

  useEffect(() => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        startGlobalDate: subDays(new Date(), 6),
        endGlobalDate: new Date()
      }
    });
  }, []);

  const setDateRange = (start, end) => {
    dispatch({
      type: 'CHANGE',
      payload: {
        startGlobalDate: start,
        endGlobalDate: end
      }
    });
  };

  const update = (key) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        key: key
      }
    });
  };

  return (
    <GlobalDateContext.Provider
      value={{
        ...state,
        setDateRange,
        update
      }}
    >
      {children}
    </GlobalDateContext.Provider>
  );
};

GlobalDateProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default GlobalDateContext;
