import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// // Pages

// const Overview = Loader(lazy(() => import('src/content/overview')));

// // Dashboards

// const Analytics = Loader(
//   lazy(() => import('src/content/dashboards/Analytics'))
// );
// const Banking = Loader(lazy(() => import('src/content/dashboards/Banking')));
// const Commerce = Loader(lazy(() => import('src/content/dashboards/Commerce')));
// const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));
// const Finance = Loader(lazy(() => import('src/content/dashboards/Finance')));
// const Fitness = Loader(lazy(() => import('src/content/dashboards/Fitness')));
// const HealthcareDoctor = Loader(
//   lazy(() => import('src/content/dashboards/Healthcare'))
// );
// const HealthcareHospital = Loader(
//   lazy(() => import('src/content/dashboards/Healthcare/HospitalView'))
// );
// const Helpdesk = Loader(lazy(() => import('src/content/dashboards/Helpdesk')));
// const Learning = Loader(lazy(() => import('src/content/dashboards/Learning')));
// const Monitoring = Loader(
//   lazy(() => import('src/content/dashboards/Monitoring'))
// );
// const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

// // Applications

// const FileManager = Loader(
//   lazy(() => import('src/content/applications/FileManager'))
// );
// const Messenger = Loader(
//   lazy(() => import('src/content/applications/Messenger'))
// );
// const Calendar = Loader(
//   lazy(() => import('src/content/applications/Calendar'))
// );
// const JobsPlatform = Loader(
//   lazy(() => import('src/content/applications/JobsPlatform'))
// );
// const ProjectsBoard = Loader(
//   lazy(() => import('src/content/applications/ProjectsBoard'))
// );
// const Mailbox = Loader(lazy(() => import('src/content/applications/Mailbox')));

// // Management

// const Users = Loader(lazy(() => import('src/content/management/Users')));
// const SingleUser = Loader(
//   lazy(() => import('src/content/management/Users/single'))
// );

// const Projects = Loader(lazy(() => import('src/content/management/Projects')));

// const Invoices = Loader(lazy(() => import('src/content/management/Invoices')));
// const SingleInvoice = Loader(
//   lazy(() => import('src/content/management/Invoices/single'))
// );

// const Products = Loader(lazy(() => import('src/content/management/Products')));
// const CreateProduct = Loader(
//   lazy(() => import('src/content/management/Products/create'))
// );
// const SingleProduct = Loader(
//   lazy(() => import('src/content/management/Products/single'))
// );

// const Shop = Loader(lazy(() => import('src/content/management/Products/shop')));

// // Auth

// const LoginCover = Loader(
//   lazy(() => import('src/content/pages/Auth/Login/Cover'))
// );
// const LoginBasic = Loader(
//   lazy(() => import('src/content/pages/Auth/Login/Basic'))
// );

// const RegisterCover = Loader(
//   lazy(() => import('src/content/pages/Auth/Register/Cover'))
// );
// const RegisterBasic = Loader(
//   lazy(() => import('src/content/pages/Auth/Register/Basic'))
// );
// const RegisterWizard = Loader(
//   lazy(() => import('src/content/pages/Auth/Register/Wizard'))
// );

// const RecoverPassword = Loader(
//   lazy(() => import('src/content/pages/Auth/RecoverPassword'))
// );

// // Status

// const Status404 = Loader(
//   lazy(() => import('src/content/pages/Status/Status404'))
// );
// const Status500 = Loader(
//   lazy(() => import('src/content/pages/Status/Status500'))
// );
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
// const StatusMaintenance = Loader(
//   lazy(() => import('src/content/pages/Status/Maintenance'))
// );

const MyAnalytics = Loader(
  lazy(() => import('src/content/dashboards/MyAnalytics'))
);

const Campaign = Loader(lazy(() => import('src/content/dashboards/Campaign')));

const Site = Loader(lazy(() => import('src/content/dashboards/Site')));

const routes = [
  // {
  //   path: 'account',
  //   children: [
  //     {
  //       path: 'login',
  //       element: (
  //         <Guest>
  //           <LoginCover />
  //         </Guest>
  //       )
  //     },
  //     {
  //       path: 'register',
  //       element: (
  //         <Guest>
  //           <RegisterCover />
  //         </Guest>
  //       )
  //     },
  //     {
  //       path: 'login-basic',
  //       element: <LoginBasic />
  //     },
  //     {
  //       path: 'login-cover',
  //       element: <LoginCover />
  //     },
  //     {
  //       path: 'recover-password',
  //       element: <RecoverPassword />
  //     },
  //     {
  //       path: 'register',
  //       element: (
  //         <Guest>
  //           <RegisterCover />
  //         </Guest>
  //       )
  //     },
  //     {
  //       path: 'register-basic',
  //       element: <RegisterBasic />
  //     },
  //     {
  //       path: 'register-cover',
  //       element: <RegisterCover />
  //     },
  //     {
  //       path: 'register-wizard',
  //       element: <RegisterWizard />
  //     }
  //   ]
  // },
  // {
  //   path: '*',
  //   element: <BaseLayout />,
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="dashboards/" replace />
  //     },
  //     {
  //       path: 'status',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="404" replace />
  //         },
  //         {
  //           path: '404',
  //           element: <Status404 />
  //         },
  //         {
  //           path: '500',
  //           element: <Status500 />
  //         },
  //         {
  //           path: 'maintenance',
  //           element: <StatusMaintenance />
  //         },
  //         {
  //           path: 'coming-soon',
  //           element: <StatusComingSoon />
  //         }
  //       ]
  //     },
  //     {
  //       path: '*',
  //       element: <Status404 />
  //     }
  //   ]
  // },
  // {
  //   path: '*',
  //   element: (
  //     <Guest>
  //       <BaseLayout />
  //     </Guest>
  //   ),
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="/coming-soon" replace />
  //     },
  //     {
  //       path: 'coming-soon',
  //       element: <StatusComingSoon />
  //     }
  //   ]
  // }
  {
    path: 'dashboards',
    element: (
      <Guest>
        <SidebarLayout />
      </Guest>
    ),
    children: [
      // {
      //   path: '/',
      //   element: <Navigate to="/dashboards/analytics/:cid" replace />
      // },
      {
        path: 'analytics/:cid',
        element: <MyAnalytics />
      },
      {
        path: 'campaign/:cid',
        element: <Campaign />
      },
      {
        path: 'site/:cid',
        element: <Site />
      }
      // {
      //   path: 'analytics',
      //   element: <Analytics />
      // },
      // {
      //   path: 'banking',
      //   element: <Banking />
      // },
      // {
      //   path: 'commerce',
      //   element: <Commerce />
      // },
      // {
      //   path: 'crypto',
      //   element: <Crypto />
      // },
      // {
      //   path: 'finance',
      //   element: <Finance />
      // },
      // {
      //   path: 'fitness',
      //   element: <Fitness />
      // },
      // {
      //   path: 'healthcare',
      //   children: [
      //     {
      //       path: '/',
      //       element: <Navigate to="hospital" replace />
      //     },
      //     {
      //       path: 'hospital',
      //       element: <HealthcareHospital />
      //     },
      //     {
      //       path: 'doctor',
      //       element: <HealthcareDoctor />
      //     }
      //   ]
      // },
      // {
      //   path: 'helpdesk',
      //   element: <Helpdesk />
      // },
      // {
      //   path: 'learning',
      //   element: <Learning />
      // },
      // {
      //   path: 'monitoring',
      //   element: <Monitoring />
      // },
      // {
      //   path: 'tasks',
      //   element: <Tasks />
      // }
    ]
  }
  // {
  //   path: 'applications',
  //   element: (
  //     <Authenticated>
  //       <SidebarLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="/applications/calendar" replace />
  //     },
  //     {
  //       path: 'calendar',
  //       element: <Calendar />
  //     },
  //     {
  //       path: 'file-manager',
  //       element: <FileManager />
  //     },
  //     {
  //       path: 'jobs-platform',
  //       element: <JobsPlatform />
  //     },
  //     {
  //       path: 'projects-board',
  //       element: <ProjectsBoard />
  //     },
  //     {
  //       path: 'messenger',
  //       element: <Messenger />
  //     },
  //     {
  //       path: 'mailbox',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="/applications/mailbox/inbox" replace />
  //         },
  //         {
  //           path: 'tag/:labelTag',
  //           element: <Mailbox />
  //         },
  //         {
  //           path: 'tag/:labelTag/:mailboxCategory',
  //           element: <Mailbox />
  //         },
  //         {
  //           path: ':categoryTag',
  //           element: <Mailbox />
  //         },
  //         {
  //           path: ':categoryTag/:mailboxCategory',
  //           element: <Mailbox />
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: 'management',
  //   element: (
  //     <Authenticated>
  //       <SidebarLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="/management/users/list" replace />
  //     },
  //     {
  //       path: 'users',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Users />
  //         },
  //         {
  //           path: ':userId',
  //           element: <SingleUser />
  //         }
  //       ]
  //     },
  //     {
  //       path: 'user',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="/management/user/2" replace />
  //         },
  //         {
  //           path: ':userId',
  //           element: <SingleUser />
  //         }
  //       ]
  //     },
  //     {
  //       path: 'projects',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="/management/projects/list" replace />
  //         },
  //         {
  //           path: 'list',
  //           element: <Projects />
  //         }
  //       ]
  //     },
  //     {
  //       path: 'commerce',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="/management/commerce/shop" replace />
  //         },
  //         {
  //           path: 'shop',
  //           element: <Shop />
  //         },
  //         {
  //           path: 'products',
  //           element: <Products />
  //         },
  //         {
  //           path: 'product',
  //           children: [
  //             {
  //               path: '/',
  //               element: (
  //                 <Navigate to="/management/commerce/product/1" replace />
  //               )
  //             },
  //             {
  //               path: 'create',
  //               element: <CreateProduct />
  //             },
  //             {
  //               path: ':productId',
  //               element: <SingleProduct />
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       path: 'invoices',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="/management/invoices/list" replace />
  //         },
  //         {
  //           path: 'list',
  //           element: <Invoices />
  //         }
  //       ]
  //     },
  //     {
  //       path: 'invoice',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="/management/invoice/1" replace />
  //         },
  //         {
  //           path: ':invoiceId',
  //           element: <SingleInvoice />
  //         }
  //       ]
  //     }
  //   ]
  // }
];

export default routes;
