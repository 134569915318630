import { useContext, useEffect, useState } from 'react';
import { IconButton, Tooltip, Hidden, Zoom } from '@material-ui/core';
import FileDownload from '@material-ui/icons/FileDownload';
import { Bars, Grid } from 'react-loading-icons';
import NProgress from 'nprogress';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

function ExportButton({ color }) {
  const { exportStatus, setExportStatus } = useContext(SidebarContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onClick = () => {
    setExportStatus(true);
    NProgress.start();
  };

  // useEffect(() => {
  //   if (!exportStatus) return;
  //   enqueueSnackbar(t('Report is done!'), {
  //     variant: 'info',
  //     anchorOrigin: {
  //       vertical: 'top',
  //       horizontal: 'center'
  //     },
  //     TransitionComponent: Zoom
  //   });
  // }, [exportStatus]);

  return (
    <>
      {!exportStatus && (
        <Tooltip arrow title={'Click to export data to excel'}>
          <IconButton
            style={{ fontSize: 16, marginLeft: 10 }}
            color={color}
            onClick={onClick}
          >
            <FileDownload style={{ marginRight: 6 }} />
            <Hidden smDown>{'.XLSX'}</Hidden>
          </IconButton>
        </Tooltip>
      )}
      {exportStatus && (
        <IconButton
          style={{ fontSize: 16, marginLeft: 10 }}
          color={'secondary'}
        >
          {/* <Bars
            width={22}
            height={22}
            fill={'#6E759F'}
            style={{ marginRight: 10 }}
          /> */}
          <Hidden smDown>{'Building...'}</Hidden>
        </IconButton>
      )}
    </>
  );
}

export default ExportButton;
