import { useContext, useState, useEffect } from 'react';

import { ThemeContext } from 'src/theme/ThemeProvider';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { experimentalStyled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays, subDays } from 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import useGlobalDate from 'src/hooks/useGlobalDate';

const DateRangeWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        // background: ${theme.colors.alpha.black[100]};
        padding: ${theme.spacing(2)};
`
);

const DateRangeToggle = (_props) => {
  const setThemeName = useContext(ThemeContext);
  const { t } = useTranslation();
  const { startGlobalDate, endGlobalDate, setDateRange } = useGlobalDate();
  const theme = useTheme();
  const [state, setState] = useState([
    {
      startDate: startGlobalDate,
      endDate: endGlobalDate,
      key: 'selection',
      autoFocus: true,
      color: theme.colors.primary.main
    }
  ]);

  useEffect(() => {
    setDateRange(state[0].startDate, state[0].endDate);
  }, [state]);

  return (
    <>
      <DateRangeWrapper>
        <DateRangePicker
          onChange={(item) => setState([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
      </DateRangeWrapper>
      <Divider />
    </>
  );
};

export default DateRangeToggle;
