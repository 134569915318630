import App from './App';
import ReactDOM from 'react-dom';
import 'src/mocks';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';
import { Provider } from 'react-redux';
import store from 'src/store';
import { AuthProvider } from './contexts/JWTAuthContext';
import { GlobalDateProvider } from './contexts/GlobalDateContext';

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <GlobalDateProvider>
        <SidebarProvider>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </SidebarProvider>
      </GlobalDateProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
