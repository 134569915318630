import { useState, createContext } from 'react';

export const SidebarContext = createContext({});

export const SidebarProvider = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [exportStatus, setExportStatus] = useState(false);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        exportStatus,
        setExportStatus
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
